"use client"
import Image from "next/image";

import NotFoundImage from "@/assets/images/state/error.png";
import Button from "@/components/atom/Button/Button";

import "./NotFound.scss"
import Header from "@/components/organism/Header/Header";
import {PAGE_URL} from "@/utils/const/url";
import {useLocale, useTranslations} from "next-intl";
import {useRouter} from "next/navigation";

const NotFound = () => {

    const t = useTranslations("notFound");
    const router = useRouter();

    return (
      <div className={"not-found"}>
         <Header />
          <div className={"container"}>
              <div className={"not-found-shadow"}>
                  <div className={"not-found-shadow-left"}>

                  </div>
                  <div className={"not-found-shadow-right"}>

                  </div>
              </div>
              <div className={"not-found-content"}>
                  <Image src={NotFoundImage} alt={"Stránka nebyla nalezena"} />
                  <h1 dangerouslySetInnerHTML={{__html: t.raw('title')}}></h1>
                  <p>{t("description")}</p>
                  <Button type={"DEFAULT"} onClick={() => router.replace(PAGE_URL.HOMEPAGE)}>
                      {t("button")}
                  </Button>
              </div>
          </div>
      </div>
    );
}

export default NotFound;
